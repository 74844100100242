<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/boletins">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/boletins"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="boletins-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <div class="field">
                            <label for="name">Descrição</label>
                            <InputText
                                id="descricao"
                                v-model.trim="form.descricao"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.descricao }"
                            />
                            <small class="p-error" v-if="submitted && !form.descricao">Descrição é obrigatória.</small>
                        </div>
                        <div class="field">
                            <label for="name">Descrição detalhada</label>
                            <Editor
                                id="descricao"
                                v-model.trim="form.descricaoDetalhada"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.descricaoDetalhada }"
                            />
                            <small class="p-error" v-if="submitted && !form.descricaoDetalhada">Descrição detalhada é obrigatória.</small>
                        </div>
                        <div class="field">
                            <label for="name">Tipo</label>
                            <Dropdown
                                id="situacao"
                                v-model="form.tipo"
                                :options="types"
                                optionValue="value"
                                optionLabel="label"
                                placeholder="Selecione..."
                            />
                            <small class="p-error" v-if="submitted && !form.tipo">Tipo é obrigatório.</small>
                        </div>
                        <div class="field">
                            <label for="name">Versão</label>
                            <InputText
                                id="versao"
                                v-model.trim="form.versao"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.versao }"
                            />
                            <small class="p-error" v-if="submitted && !form.versao">Versão é obrigatório.</small>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '@/services/store';

export default {
    data() {
        return {
            form: {
                ativo: true
            },
            submitted: false,
            customers: [],
            types: [
                { label: 'Usuário Cliente', value: 'Usuário Cliente' },
                { label: 'Usuário Externo', value: 'Usuário Externo' }
            ]
        };
    },
    created() {
        this.service = new BaseService('/boletins');
    },
    async mounted() {
        this.loadCustomers();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar atualização' : 'Adicionar atualização';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            if (!this.form.descricao)
                return true;
        },
        async loadCustomers() {
            const { data } = await this.$serviceCustomer.findAll({});
            this.customers = data;
        }
    },
    components: { AppInfoManual }
};
</script>